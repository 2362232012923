import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import Modal from "../../components/modal/pipefy-modal"

import "./hero.css"

const Banner = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "new-images/pipefy-db.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-whitefade-pipefy"
        // preserveStackingContext
        style={{ backgroundPosition: "center", backgroundSize: "contain" }}
      >
        <div className="container">
          <div className="row pb-3">
            <div className="col-lg-6 mr-auto">
              <div className="hero-custom-content-home">
                {/* <div className="hero-procurement"> */}
                <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                  Pipefy Solutions
                </h5>
                <h1 className="page-title-quickbase font-weight-medium mt-0 mb-4">
                  Automate Away Complex Processes in Minutes
                </h1>
                <p>
                  Set them free with automations built quickly on a secure,
                  low-code platform for workflows and Business Process
                  Automation (BPA).
                </p>
                <div className="mt-4">
                  {/* <Modal /> */}
                  <div className="mt-4">
                    <Link
                      aria-label="link"
                      className="font-weight-medium"
                      to="/contact/"
                    >
                      Speak to a Technology Consultant
                      <i className="ml-2 item-arrow" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
                {/* <div className="formula mt-5">
                  <StaticImage
                    placeholder="dominantColor"
                    className="img-fluid rounded ml-auto"
                    src="../../images/formula.png"
                    alt="Solution Provider"
                    layout="constrained"
                    // width={150}
                    height={150}
                    loading="lazy"
                  />
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 p-5"
              style={{ position: "relative" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner
