import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"

// import QuickBaseSolutionsBanner from "../images/new-images/QuickBaseSolutionsBanner.jpg"

import Banner from "../components/hero/banner-pipefy.js"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PipefySections = loadable(() =>
  pMinDelay(import("../components/PipefySections"), 1000)
)

class PipefyPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Pipefy Solutions | Quandary Consulting Group"
          description="Pipefy is a low-code business process automation software that can help your teams manage and automate workflows. Learn more."
        />
        {/* <LazyLoadComponent>
          <PartnerSwiper />
        </LazyLoadComponent> */}
        <PipefySections />
      </Layout>
    )
  }
}

export default PipefyPage

export const pipefyPageQuery = graphql`
  query pipefyPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
